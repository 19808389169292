/*
  formSlice.js
  
  Stores forms values.
  Used by any component rendering input forms.
  Values passed to API methods. 
*/

import { createSlice } from "@reduxjs/toolkit";

/*
  Initial value
*/
const initialState = {
  value: null,
};

export const formSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setFormData } = formSlice.actions;

export default formSlice.reducer;
