/*
  modalSlice.js
  
  Stores modal values.
  Used by any component displaying modal windows.
  Shows text or form components from forms subdirectory
*/

import { createSlice } from "@reduxjs/toolkit";

/*
  Initial values
*/
const initialState = {
  value: {
    show: false,
    title: "",
    body: "",
    confirm: "",
    showConfirm: false,
    cancel: "",
    confirmAction: null,
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
