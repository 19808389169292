import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "",
};

export const editTypeSlice = createSlice({
  name: "editType",
  initialState,
  reducers: {
    setEditType: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setEditType } = editTypeSlice.actions;

export default editTypeSlice.reducer;
