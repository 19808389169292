/*
  reloadSlice.js
  
  Stores reload flag.
  Used by any component that needs to trigger other components reload.
*/

import { createSlice } from "@reduxjs/toolkit";

/*
  Initial value
*/
const initialState = {
  value: false,
};

export const reloadSlice = createSlice({
  name: "reload",
  initialState,
  reducers: {
    setReload: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setReload } = reloadSlice.actions;

export default reloadSlice.reducer;
