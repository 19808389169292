/*
  userSlice.js
  
  Stores loggedin user information.
  Used by any component that needs to display user information or handle functionality based on user type.
*/

import { createSlice } from "@reduxjs/toolkit";

/*
  Initial value
*/
const initialState = {
  value: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
