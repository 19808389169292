import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import reloadReducer from "./reloadSlice";
import modalReducer from "./modalSlice";
import formReducer from "./formSlice";
import alertReducer from "./alertSlice";
import overlayReducer from "./overlaySlice";
import editTypeReducer from "./editTypeSlice";
import timezonesReducer from "./timezonesSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    reload: reloadReducer,
    modal: modalReducer,
    formData: formReducer,
    alert: alertReducer,
    overlay: overlayReducer,
    editType: editTypeReducer,
    timezones: timezonesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
