/*
  timezonesSlice.js
  
  Stores timezones values.
*/

import { createSlice } from "@reduxjs/toolkit";

/*
  Initial value
*/
const initialState = {
  value: [],
};

export const timezonesSlice = createSlice({
  name: "timezones",
  initialState,
  reducers: {
    setTimezones: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setTimezones } = timezonesSlice.actions;

export default timezonesSlice.reducer;
