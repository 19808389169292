/*
  alertSlice.js
  
  Stores alert values.
  Used by all components displaying alerts.
*/

import { createSlice } from "@reduxjs/toolkit";

/*
  Initial values
*/
const initialState = {
  value: {
    show: false,
    title: "",
  },
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      const s = state;
      s.value = action.payload;
    },
  },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
